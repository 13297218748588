<template>
  <v-container fluid tag="section">

    <div class="text-end">
      <v-btn outlined class="font-weight-bold me-2" :to="`/`">{{ $t('Cancel') }}</v-btn>
      <v-btn color="purple" class="font-weight-bold me-0" @click="onSubmit" :loading="loading" :disabled="loading">{{ $t('Save') }}</v-btn>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card class="rounded-lg mt-2">
          <v-card-text class="px-0 py-0">
            <v-form>
              <v-container>
                <v-text-field v-model="item.username" :label="$t('Username')"></v-text-field>
                <v-text-field v-model="item.email" :label="$t('Email')"></v-text-field>
                <v-text-field v-model="item.password" :label="$t('Password')"></v-text-field>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import profileService from '@/services/profileService'

export default {
  data() {
    return {
      loading: false,
      item: {
        username: null,
        email: null,
        password: null,
      }
    }
  },
  created() {
    this.getProfile()
  },
  methods: {
    async getProfile () {
      try {
        const result = await profileService.get()
        this.item = result.data
      } catch (error) {
        this.$dialog.message.error(error.message)
      }
    },
    async onSubmit () {
      this.loading = true
      try {
        await profileService.edit(this.item)
        this.$dialog.message.success(this.$t('Success'))
      } catch (error) {
        this.$dialog.message.error(error.message)
      }
      this.loading = false
    },
  }
}
</script>